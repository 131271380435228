// @ts-nocheck
import Moralis from 'moralis'
import { navigate } from 'gatsby'
import { useReducer } from 'react'
import { toast } from 'react-toastify'
import avatar from '../images/common/avater.png'
import {
  NFTABI,
  MarketplaceABI,
  nftContract,
  marketContract,
  CHAIN,
} from './config'
let web3EnableHandler,
  chainChangeHandler,
  accountChangeHandler,
  connectHandler,
  handleAccounts
export const useMoralis = () => {
  if (typeof window !== `undefined`) {
    const [, forceUpdate] = useReducer(x => x + 1, 0)
    const serverUrl = process.env.GATSBY_SERVER_URL
    const appId = process.env.GATSBY_APPLICATION_ID
    Moralis.start({ serverUrl, appId })
    const UserQuery = new Moralis.Query(Moralis.User)
    const signingMessage = 'CTIO Account Verification'
    if (!web3EnableHandler) {
      web3EnableHandler = Moralis.onWeb3Enabled(result => {
        forceUpdate()
      })
    }
    if (!chainChangeHandler) {
      chainChangeHandler = Moralis.onChainChanged(chain => {
        console.log(chain)
      })
    }
    if (!accountChangeHandler) {
      accountChangeHandler = Moralis.onAccountChanged(chain => {
        console.log(chain)
      })
    }
    if (!connectHandler) {
      connectHandler = Moralis.onConnect(provider => {
        console.log(provider)
      })
    }
    const handleAccountsChanged = async accounts => {
      try {
        await Moralis.enableWeb3()
        console.log(accounts)
        const confirmed = confirm('Link this address to your account?')
        if (confirmed) {
          const transaction = await Moralis.link(accounts[0])
          if (transaction) {
            toast.success('Address added!')
            window?.location?.reload()
          }
        }
      } catch (err) {
        toast.error(err.message)
      }
    }
    if (!handleAccounts) {
      handleAccounts = window?.ethereum.on(
        'accountsChanged',
        handleAccountsChanged,
      )
    }
    const toastMsg = ({
      id,
      message,
      type,
      timeout = 4000,
      isLoading = false,
      awaitObj = {},
    }) => {
      if (awaitObj) {
        return toast.update(id, {
          render: message,
          type,
          isLoading,
          autoClose: timeout,
          limit: 1,
        })
      }
    }
    const userLogin = async ({ username, password }) => {
      const id = toast.loading('Logining')
      try {
        const res = await Moralis.User.logIn(username, password)
        if (res) {
          toastMsg({ id, message: 'Welcome to CTIO!', type: 'success' })
          console.log(res)
          setTimeout(() => {
            navigate('/user')
          }, 2000)
        }
      } catch (error) {
        toastMsg({ id, message: error.message, type: 'error' })
      }
    }
    const userSign = async ({
      username,
      password,
      email,
      firstName,
      lastName,
    }) => {
      const id = toast.loading('Registering')
      const user = new Moralis.User()
      user.set('username', username)
      user.set('password', password)
      user.set('email', email)
      user.set('firstName', firstName)
      user.set('lastName', lastName)
      try {
        const res = await user.signUp()
        if (res) {
          toastMsg({
            id,
            message: "You've registered successfully!",
            type: 'success',
          })
          navigate('/login')
        }
      } catch (error) {
        toastMsg({ id, message: error.message, type: 'error' })
      }
    }
    const logout = async () => {
      const id = toast.loading('Logouting')
      try {
        await Moralis.User.logOut()
        toastMsg({ id, message: 'Logout successfully', type: 'success' })
        setTimeout(() => {
          navigate('/')
        }, 2000)
        sessionStorage.clear()
        localStorage.clear()
        forceUpdate()
      } catch (error) {
        toastMsg({ id, message: error.message, type: 'error' })
      }
    }
    const updateUser = async data => {
      const id = toast.loading('Updating')
      const { firstName, lastName, email, about } = data
      const user = Moralis.User.current()
      if (user) {
        user.set('firstName', firstName)
        user.set('lastName', lastName)
        user.set('email', email)
        user.set('about', about)
        try {
          const res = await user.save()
          if (res) {
            toastMsg({ id, message: 'User information saved successfully!' })
          }
        } catch (error) {
          toastMsg({ id, message: error.message, type: 'error' })
        }
      }
    }
    const checkIfLinked = async () => {
      const currentUser = Moralis.User.current()
      const isConnect = window?.ethereum?.selectedAddress
      const isMetaMaskLogin = currentUser?.attributes?.accounts
      if (isMetaMaskLogin) {
        const accountLinked =
          currentUser?.attributes?.accounts?.includes(
            window.ethereum.selectedAddress,
          ) || false
        return accountLinked
      } else {
        if (!!isConnect) {
          return true
        } else {
          return false
        }
      }
    }
    const connectWallet = async () => {
      const id = toast.loading('Linking')
      const isLinked = await checkIfLinked()
      try {
        if (isLinked === true) {
          toastMsg({ id, message: 'Wallet is linked!', type: 'success' })
        }
        if (isLinked === false) {
          await Moralis.enableWeb3()
          toastMsg({ id, message: 'Wallet is linked!', type: 'success' })
        }
        forceUpdate()
      } catch (error) {
        toastMsg({ id, message: error.message, type: 'error' })
      }
    }
    const getUserBalances = async () => {
      const user = Moralis.User.current()
      const address = window?.ethereum?.selectedAddress
      try {
        if (user) {
          if (address) {
            const balance = await Moralis.Web3API.account.getNativeBalance({
              chain: CHAIN,
              address,
            })
            return await Moralis.Units.FromWei(balance.balance)
          } else {
            toast.warn('Please link your wallet first')
          }
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    const updateAvatar = async ({ files, email }) => {
      const id = toast.loading('uploading avatar 🛠️')
      let user = Moralis.User.current()
      try {
        if (!user) {
          toastMsg({
            id,
            message: 'Uploading files requires a wallet connection.',
            type: 'warn',
          })
        } else {
          if (files?.length) {
            const moralisAvatar = new Moralis.File(files[0].name, files[0])
            user.set('avatar', moralisAvatar)
            const res = await user.save()
            if (res) {
              toastMsg({
                id,
                message: 'Uploaded avatar successfully! 👌',
                type: 'success',
              })
              return res
            }
          }
        }
      } catch (error) {
        toastMsg({ id, message: error.message, type: 'error' })
      }
    }

    const uploadFiles = async ({ files, name }: { files: any; name?: any }) => {
      let user = Moralis.User.current()
      try {
        if (!user) {
          toast.warn('Uploading files requires a wallet connection.')
        } else {
          if (files?.length > 0) {
            const fileName = name
              ? `${name}.${files[0].name.split('.')[1]}`
              : files[0].name
            const moralisFile = new Moralis.File(fileName, files[0])
            return await moralisFile.save()
          }
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    const uploadFilesIPFS = async file => {
      const name = file.name
      const newFile = new Moralis.File(name, file)
      try {
        await newFile.saveIPFS()
        const imageURI = newFile.ipfs()
        return imageURI
      } catch (error) {
        toast.error(error.message)
      }
    }
    const uploadMetadata = async metadata => {
      const id = toast.loading('coin being minted 🛠️')
      const user = Moralis.User.current()
      if (user) {
        const metadataFile = new Moralis.File('metadata.json', {
          base64: btoa(JSON.stringify(metadata)),
        })
        try {
          await metadataFile.saveIPFS()
          const metadataURI = metadataFile.ipfs()
          const transaction = await mintToken(metadataURI)
          toastMsg({
            id,
            message: 'Your transaction is accelerating. . .🚀',
            isLoading: true,
            awaitObj: transaction,
          })
          const result = await transaction.wait()
          toastMsg({
            id,
            message: 'Congratulations, your NFT minting is successful! 👌',
            type: 'success',
            awaitObj: result,
          })
          return result
        } catch (error) {
          toastMsg({ id, message: error.message, type: 'error' })
        }
      } else {
        toast.warn('Please connect your wallet first!')
      }
    }
    const syncMetadata = async ({
      token_id,
      token_address = nftContract,
      flag = 'uri',
    }) => {
      try {
        await Moralis.enableWeb3()
        const options = {
          chain: CHAIN,
          address: token_address,
          token_id,
          flag,
        }
        await Moralis.Web3API.token.reSyncMetadata(options)
      } catch (error) {
        toast.error(error.message)
      }
    }
    const mintToken = async (_uri, token_address = nftContract) => {
      try {
        await Moralis.enableWeb3()
        const options = {
          chain: CHAIN,
          contractAddress: token_address,
          functionName: 'mint',
          abi: NFTABI,
          params: {
            tokenURI: _uri,
          },
        }
        return await Moralis.executeFunction(options)
      } catch (error) {
        toast.error(error.message)
      }
    }
    const tranferNFTs = async (
      from,
      to,
      tokenId,
      token_address = nftContract,
    ) => {
      const id = toast.loading('Transfering')
      try {
        await Moralis.enableWeb3()
        const options = {
          chain: CHAIN,
          contractAddress: token_address,
          functionName: 'transferFrom',
          abi: NFTABI,
          params: {
            from,
            to,
            tokenId,
          },
        }
        const transaction = await Moralis.executeFunction(options)
        toastMsg({
          id,
          message: 'Your transaction is accelerating. . .🚀',
          isLoading: true,
          awaitObj: transaction,
        })
        const result = await transaction.wait()
        toastMsg({
          id,
          message: `successfully transferred to ${to} 🎉`,
          type: 'success',
          awaitObj: result,
        })
        return result
      } catch (error) {
        toastMsg({ id, message: error.message, type: 'error' })
      }
    }
    const getNFTsByContract = async ({
      address,
      token_address = nftContract,
      format,
      offset,
      limit,
    }) => {
      try {
        const user = Moralis.User.current()
        const nfts = await Moralis.Web3API.account.getNFTsForContract({
          chain: CHAIN,
          address,
          token_address,
          format,
          offset,
          limit,
        })
        if (nfts.result.length > 0) {
          const promises = nfts?.result?.map((item, index) => {
            if (!item.metadata) {
              return fetch(item.token_uri)
                .then(response => response.json())
                .catch(err => {
                  toast.error(err.message)
                })
            } else {
              nfts.result[index].metadata = JSON.parse(item.metadata)
            }
            return null
          })
          const responses = await Promise.allSettled(promises)
          let ownerPromises, nftPromises, nftResponses
          nftPromises = nfts?.result?.map(item => {
            return getSaleNFT(item.token_id)
          })
          nftResponses = await Promise.allSettled(nftPromises)
          if (address === marketContract) {
            ownerPromises = nftResponses?.map((item, index) => {
              return getUserinfoByAddress(item.value.seller)
            })
          } else if (address === user.get('ethAddress')) {
            ownerPromises = new Array(responses.length).fill({
              attributes: { ...user.attributes },
            })
          } else {
            //TODO:When the contract address is the address of another owner
          }
          if (ownerPromises?.length > 0) {
            const ownerResponses = await Promise.allSettled(ownerPromises)
            const ownerFilterResponses = ownerResponses.map(item => {
              if (item.status === 'fulfilled') {
                return item.value
              } else {
                return null
              }
            })
            responses.forEach((item, index) => {
              nfts.result[index].userInfo = {
                avatarUrl:
                  ownerFilterResponses[index]?.attributes?.avatar?._url ||
                  avatar,
                username:
                  ownerFilterResponses[index]?.attributes?.username ||
                  'anonymous',
                address:
                  ownerFilterResponses[index]?.attributes?.ethAddress ||
                  nftResponses[index]?.value?.seller ||
                  '',
              }
              if (item.status === 'fulfilled') {
                nfts.result[index].metadata = item.value
              }
            })
          }
        }
        return nfts
      } catch (error) {
        toast.error(error.message)
      }
    }
    const getNFTsByUser = async ({ address, format, offset, limt }) => {
      const options = {
        chain: CHAIN,
        address,
        format,
        offset,
        limt,
      }
      try {
        return await Moralis.Web3API.account.getNFTs(options)
      } catch (error) {
        toast.error(error.message)
      }
    }
    const getUserinfoByAddress = async address => {
      try {
        const params = { address }
        return await Moralis.Cloud.run('getAllUser', params)
      } catch (error) {
        toast.error(error.message)
      }
    }
    const getAllNFTs = async ({
      address = nftContract,
      format,
      offset,
      limit,
    }) => {
      const options = {
        address,
        chain: CHAIN,
        format,
        offset,
        limit,
      }
      try {
        const nfts = await Moralis.Web3API.token.getAllTokenIds(options)
        const promises = nfts.result.map(item => {
          return fetch(item.token_uri).then(response => response.json())
        })
        const responses = await Promise.allSettled(promises)
        responses.forEach((item, index) => {
          if (item.status === 'fulfilled') {
            nfts.result[index].metadata = item.value
          }
        })
        return nfts
      } catch (error) {
        toast.error(error.message)
      }
    }
    const getApproveSell = async (tokenId, token_address = nftContract) => {
      try {
        await Moralis.enableWeb3()
        const options = {
          chain: CHAIN,
          contractAddress: token_address,
          functionName: 'getApproved',
          abi: NFTABI,
          params: {
            tokenId,
          },
        }
        const res = await Moralis.executeFunction(options)
        return res
      } catch (error) {
        toast.error(error.message)
      }
    }
    // Allow your nft to sell
    const approveSell = async (tokenId, token_address = nftContract) => {
      const id = toast.loading('Approving')
      await Moralis.enableWeb3()
      const options = {
        chain: CHAIN,
        contractAddress: token_address,
        functionName: 'approve',
        abi: NFTABI,
        params: {
          to: marketContract,
          tokenId,
        },
      }
      try {
        const transaction = await Moralis.executeFunction(options)
        toastMsg({
          id,
          message: 'Awaiting complete approval. . .🚀',
          isLoading: true,
          awaitObj: transaction,
        })
        const result = await transaction.wait()
        toastMsg({
          id,
          message: 'Successfully approved NFT 🎉',
          type: 'success',
          awaitObj: result,
        })
        return result
      } catch (error) {
        toastMsg({ id, message: error.message, type: 'error' })
      }
    }
    // list your nft
    const listNFTs = async (tokenId, token_address, price) => {
      const id = toast.loading('Listing your NFT')
      try {
        await Moralis.enableWeb3()
        console.log(tokenId, token_address, Moralis?.Units.ETH(price))
        const options = {
          chain: CHAIN,
          contractAddress: marketContract,
          functionName: 'makeItem',
          abi: MarketplaceABI,
          params: {
            _nft: token_address,
            _tokenId: tokenId,
            _price: Moralis?.Units.ETH(price),
          },
        }
        const transaction = await Moralis.executeFunction(options)
        toastMsg({
          id,
          message: 'Waiting to finish listing. . .🚀',
          isLoading: true,
          awaitObj: transaction,
        })
        const result = await transaction.wait()
        toastMsg({
          id,
          message: 'Successfully listed NFT 🎉',
          type: 'success',
          awaitObj: result,
        })
        return result
      } catch (error) {
        toastMsg({ id, message: error.message, type: 'error' })
      }
    }
    const getNFTMetadata = async ({
      token_id,
      address = nftContract,
      format,
      offset,
      limit,
    }) => {
      const options = {
        address,
        token_id,
        chain: CHAIN,
        format,
        offset,
        limit,
      }
      try {
        return await Moralis.Web3API.token.getTokenIdMetadata(options)
      } catch (error) {
        toast.error(error.message)
      }
    }
    const getSaleNFT = async tokenId => {
      try {
        const NFTSaleObject = Moralis.Object.extend('NFTsale')
        const NFTSaleQuery = new Moralis.Query(NFTSaleObject)
        NFTSaleQuery.equalTo('tokenId', tokenId).descending('itemId')
        const results = await NFTSaleQuery.first()
        if (results) {
          return results.attributes
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    const getSoldNFT = async seller => {
      try {
        const NFTSoldObject = Moralis.Object.extend('NFTsold')
        const NFTSoldQuery = new Moralis.Query(NFTSoldObject)
        NFTSoldQuery.equalTo('seller', seller).descending('itemId')
        return await NFTSoldQuery.find()
      } catch (error) {
        toast.error(error.message)
      }
    }
    const getSoldNFTsforUser = async seller => {
      try {
        const tokenIdList = await getSoldNFT(seller)
        if (tokenIdList?.length > 0) {
          const idList = [
            ...new Set(tokenIdList?.map(item => item.attributes.tokenId)),
          ]
          const NFTlist = tokenIdList?.filter(item =>
            idList.includes(item.attributes.tokenId),
          )
          const infoPromise = NFTlist?.map(item =>
            item.attributes.buyer
              ? getUserinfoByAddress(item.attributes.buyer)
              : null,
          )
          const infoResponse = await Promise.allSettled(infoPromise)
          const infoResult = infoResponse.map((item, index) =>
            item?.status === 'fulfilled'
              ? item?.value?.attributes || {
                  avatar: { _url: avatar },
                  username: 'anonymous',
                  ethAddress: NFTlist[index].attributes.buyer,
                }
              : null,
          )
          const res = await getAllNFTs({})
          const NFTsList = res.result?.filter(item =>
            idList.includes(item.token_id),
          )
          NFTsList.forEach((item, index) => {
            // FIXME: findIndex maybe wrong
            const currentInfo =
              infoResult[idList.findIndex(value => value === item.token_id)]
            NFTsList[index].userInfo = {
              avatarUrl: currentInfo?.avatar?._url || avatar,
              username: currentInfo?.username || 'anonymous',
              address: currentInfo?.ethAddress || '',
            }
          })
          return NFTsList
        } else {
          return []
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    const getTokenUSD = async ({ address, to_block, exchange }) => {
      try {
        const options = {
          chain: CHAIN,
          exchange,
          address,
          to_block,
        }

        return await Moralis.Web3API.token.getTokenPrice(options)
      } catch (error) {
        toast.error(error.message)
      }
    }
    const purchaseNFT = async (itemId, price, feePercent = 5) => {
      const id = toast.loading('Trading')
      const formatPrice = Moralis?.Units.ETH(price)
      const feeBase =
        (BigInt(formatPrice) * BigInt(feePercent + 100)) / BigInt(100)
      const feeAccount = BigInt(210000 * parseInt(price))
      const total = String(feeBase + feeAccount)
      try {
        await Moralis.enableWeb3()
        const options = {
          chain: CHAIN,
          contractAddress: marketContract,
          functionName: 'purchaseItem',
          abi: MarketplaceABI,
          msgValue: total,
          params: {
            _itemId: itemId,
          },
        }
        const transaction = await Moralis.executeFunction(options)
        toastMsg({
          id,
          message: 'Waiting for transaction to complete. . .🚀',
          isLoading: true,
          awaitObj: transaction,
        })
        const result = await transaction.wait()
        toastMsg({
          id,
          message: 'Successful NFT transaction 🎉',
          type: 'success',
          awaitObj: result,
        })
      } catch (error) {
        toastMsg({ id, message: error.message, type: 'error' })
      }
    }
    const revokeNFT = async _itemId => {
      const id = toast.loading('Revoking')
      try {
        await Moralis.enableWeb3()
        const options = {
          chain: CHAIN,
          contractAddress: marketContract,
          functionName: 'revokeItem',
          abi: MarketplaceABI,
          params: {
            _itemId,
          },
        }
        const transaction = await Moralis.executeFunction(options)
        toastMsg({
          id,
          message: 'Waiting to revoke NFT. . .🚀',
          isLoading: true,
          awaitObj: transaction,
        })
        const result = await transaction.wait()
        toastMsg({
          id,
          message: 'Successfully revoke NFT 🎉',
          type: 'success',
          awaitObj: result,
        })
      } catch (error) {
        toastMsg({ id, message: error.message, type: 'error' })
      }
    }
    const getGasFromEthTransactions = async (limit = 10) => {
      try {
        const query = new Moralis.Query('EthTransactions')
        query.descending('createdAt').limit(limit)
        const result = await query.find()
        return result.map(item => item.attributes)
      } catch (error) {
        toast.error(error.message)
      }
    }
    const getSoldData = async () => {
      try {
        const NFTSoldObject = Moralis.Object.extend('NFTsold')
        const NFTSoldQuery = new Moralis.Query(NFTSoldObject)
        NFTSoldQuery.select('price')
        const totalData = await NFTSoldQuery.find()
        const totalTransactions = totalData.map(item => item.attributes.price)
        const totalPrice = totalTransactions.reduce(
          (sum, item) => BigInt(sum) + BigInt(item),
        )
        return Moralis.Units.FromWei(String(totalPrice))
      } catch (error) {
        toast.error(error.message)
      }
    }
    const getTotalTransactions = async () => {
      try {
        const query = new Moralis.Query('EthTransactions')
        return await query.count()
      } catch (error) {
        toast.error(error.message)
      }
    }
    const getTotalMintNFT = async () => {
      try {
        const query = new Moralis.Query('NFTmint')
        return await query.count()
      } catch (error) {
        toast.error(error.message)
      }
    }
    return {
      Moralis,
      getTotalMintNFT,
      getTotalTransactions,
      getSoldData,
      getGasFromEthTransactions,
      purchaseNFT,
      getSaleNFT,
      getUserinfoByAddress,
      getTokenUSD,
      mintToken,
      getUserBalances,
      getNFTsByContract,
      getSoldNFTsforUser,
      getNFTMetadata,
      getNFTsByUser,
      getAllNFTs,
      uploadFilesIPFS,
      uploadMetadata,
      updateUser,
      userLogin,
      userSign,
      approveSell,
      listNFTs,
      revokeNFT,
      tranferNFTs,
      getApproveSell,
      syncMetadata,
      logout,
      connectWallet,
      uploadFiles,
      updateAvatar,
    }
  }
  return {}
}
