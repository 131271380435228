import { Link } from 'gatsby'
import { useState, useEffect } from 'react'

import logo from '../images/common/logo.png'
import avatar from '../images/common/avater.png'
import { localGet } from '../utils/localStorage'
import { useMoralis } from '../hooks/moralis'
import classnames from 'classnames'
import { toast } from 'react-toastify'

type HeaderType = {
  className?: string
}
const Header: React.FC<HeaderType> = ({ className }) => {
  const { connectWallet, Moralis } = useMoralis()
  const [win, setWin] = useState<Window>()
  const [userInfo, setUserInfo] = useState<any>()
  const [address, setAddress] = useState<string>()
  const [isConnectWallet, setIsConnectWallet] = useState(false)
  const [headerFixedStyle, setHeaderFixedStyle] = useState(false)
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [router, setRouter] = useState([
    {
      path: '/',
      name: '$CTIO',
      subExpansion: false, // Check whether the mobile phone menu is expanded
      routes: [
        { path: '/coin', name: 'Coin' },
        { path: '/market', name: 'Market' },
        // { path: '/send', name: 'Send' },
      ],
    },
    {
      path: '/aboutNFT',
      name: 'About NFT',
      routes: [
        { path: '/createNFT', name: 'CreateNFT' },
        { path: '/NFTFeatured', name: 'Featured' },
        { path: '/NFTMarket', name: 'Market' },
        { path: '/NFTCreators', name: 'Creators' },
        { path: '/NFTCollections', name: 'Collections' },
      ],
    },
    { path: '/learn', name: 'Learn', subExpansion: false },
    { path: '/projects', name: 'Projects', subExpansion: false },
    // { path: '/ourRoadmap', name: 'OurRoadmap', subExpansion: false },
    { path: '/consulting', name: 'Consulting', subExpansion: false },
  ])
  const changeShowMobileMenu = () => {
    if (!showMobileMenu) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    setShowMobileMenu(!showMobileMenu)
  }
  const headerStyleChange = () => {
    // Page scrolling fixed head
    if (document.documentElement.scrollTop <= 0) {
      setHeaderFixedStyle(false)
    } else {
      setHeaderFixedStyle(true)
    }
  }
  const signWithEthereum = async () => {
    const user = await Moralis?.authenticate()
    if (user) {
      toast.success('Welcome to CTIO')
      setTimeout(() => {
        window.location.href = '/user'
      }, 1000)
    }
  }
  useEffect(() => {
    headerStyleChange()
    if (typeof window !== 'undefined') {
      setWin(window)
      window.onscroll = headerStyleChange
      setAddress(window?.ethereum?.selectedAddress)
      setUserInfo(Moralis?.User.current() || null)
    }
  }, [])

  return (
    <header className="font-regularText fixed top-0 h-20 z-[999] w-full sm:h-[57px]">
      <div
        className={`fixed top-0 h-20 z-[9999] w-full sm:h-[57px] transition-all ${headerFixedStyle
          ? 'backdrop-saturate-[180%] backdrop-blur-[3px] bg-[rgba(255,255,255,0.8)] shadow-[0_8px_10px_rgb(0_0_0/2%)]'
          : 'bg-transparent'
          } ${showMobileMenu ? '!bg-white' : 'bg-transparent'}`}
      >
        <div
          className={classnames(
            'flex items-center justify-between h-full mx-auto max-w-7xl md:px-16 sm:px-4',
            className,
          )}
        >
          <Link
            to="/"
            className="w-[167px] h-[45px] shrink-0 sm:w-[89px] sm:h-[25px]"
          >
            <img src={logo} alt="logo" />
          </Link>
          {/* Winodw Menu */}
          <div className="h-full shrink-0 sm:hidden mr-[54px] flex-1 md:mr-6 sm:mr-0">
            <ul className="flex justify-end h-full">
              {router.map((x, i) => (
                <li
                  className="px-[36px] leading-[80px] relative group md:px-[18px]"
                  key={i}
                >
                  <Link
                    to={x.routes ? '' : x.path}
                    className={`whitespace-nowrap ${[x.path, ...(x.routes?.map(y => y.path) ?? [])].includes(
                      win?.location?.pathname ?? '',
                    )
                      ? 'text-[#1E94FC]'
                      : 'text-[#000]'
                      }`}
                  >
                    {x.name}
                    {x.routes && (
                      <svg
                        className="inline-block w-3 h-3"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    )}
                  </Link>
                  {/* Sub Menu */}
                  {x.routes && (
                    <div className="w-[120px] bg-white shadow-[0_0_24px_1px_rgba(0,0,0,0.1)] rounded-lg absolute top-[70px] left-1/2 -translate-x-1/2 flex-col text-center hidden group-hover:flex hover:flex overflow-hidden">
                      {x.routes.map((y, j) => (
                        <Link
                          to={y.path}
                          key={j}
                          className={`py-2 hover:bg-[#eef7ff] h-12 leading-8 ${(win?.location?.pathname ?? '') === y.path
                            ? 'text-[#1E94FC]'
                            : 'text-[#000]'
                            }`}
                        >
                          {y.name}
                        </Link>
                      ))}
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
          {/* LoginState */}
          <div className="h-[42px] flex items-center justify-end">
            {userInfo && address ? (
              <div className="cursor-pointer shadow-sm w-[120px] mr-2 px-4 py-2 rounded-2xl bg-white">
                <span title={address}>
                  {`${address?.slice(0, 4)}...${address?.slice(-4)}`}
                </span>
              </div>
            ) : (
              ''
            )}
            {address ? userInfo ? (
              <Link
                to="/user"
                className="w-[42px] h-[42px] sm:w-9 sm:h-9 rounded-full object-cover overflow-hidden"
              >
                <img
                  src={
                    Moralis?.User.current()?.attributes?.avatar?._url || avatar
                  }
                  alt="avatar"
                />
              </Link>
            ) : (
              <>
                <Link
                  to="/login"
                  className="whitespace-nowrap py-[10px] px-[18px] text-[#828282] leading-[18px] text-center text-[14px] rounded-[60px] border border-solid border-[#828282] md:border-[#1E88E5] md:text-[#1E88E5] sm:py-[5px] sm:px-[10px] sm:text-[8px]"
                >
                  Sign in with Email
                </Link>
                <button
                  onClick={signWithEthereum}
                  className="whitespace-nowrap mr-1 ml-[13px] py-[12px] px-[18px] bg-blue-500 bg-[linear-gradient(270deg,#1E88E5_0%,#64B5F6_100%)] text-white leading-[18px] text-center text-[14px] rounded-[60px] sm:hidden hover:shadow-[0_6px_18px_4px_#C0E2FF]"
                >
                  Sign in with Ethereum
                </button>
              </>
            ) : (
              <button
                onClick={connectWallet}
                className="whitespace-nowrap mr-1 ml-[13px] py-[12px] px-[18px] bg-blue-500 bg-[linear-gradient(270deg,#1E88E5_0%,#64B5F6_100%)] text-white leading-[18px] text-center text-[14px] rounded-[60px] sm:hidden hover:shadow-[0_6px_18px_4px_#C0E2FF]"
              >
                Link Wallet
              </button>
            )}
            <div
              className="hidden ml-[18px]  w-[36px] h-[36px] sm:w-6 sm:h-6 sm:block cursor-pointer sm:cursor-default"
              onClick={() => changeShowMobileMenu()}
            >
              <svg
                className="text-[#BDBDBD]"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                {showMobileMenu ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                )}
              </svg>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile */}
      <div className="hidden sm:block">
        {/* Mask */}
        <div
          className={`fixed top-0 left-0 w-full h-full z-[99] ackdrop-saturate-[180%] backdrop-blur-[6px] transition-all duration-300 ${showMobileMenu
            ? 'visible bg-[rgba(0,0,0,0.4)]'
            : 'invisible bg-[rgba(0,0,0,0)]'
            }`}
        />
        {/* Menu */}
        <div
          className={`fixed top-20 sm:top-[57px] w-full bg-white rounded-b-2xl transition-all duration-300 z-[100] overflow-hidden ${showMobileMenu ? 'h-[450px]' : 'h-0'
            }`}
        >
          <div className="h-[300px] overflow-hidden overflow-y-auto  scrollbar-no-style">
            {router.map((x, i) => {
              return (
                <div key={i}>
                  {x.routes ? (
                    <div
                      className="h-[51px] mt-6 flex justify-between items-center border-b border-solid border-[#F2F2F2] pb-4 mx-8"
                      onClick={() =>
                        x.routes &&
                        setRouter(
                          router.map(y =>
                            y.name !== x.name
                              ? { ...y, subExpansion: false }
                              : { ...y, subExpansion: !y.subExpansion },
                          ),
                        )
                      }
                    >
                      <span className="font-boldText text-lg text-[#333]">
                        {x.name}
                      </span>
                      <svg
                        className={`w-6 h-6 text-[#BDBDBD] transition-all ${x.subExpansion ? 'rotate-90' : ''
                          }`}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  ) : (
                    <Link
                      to={x.path}
                      className="h-[51px] mt-6 flex justify-between items-center border-b border-solid border-[#F2F2F2] pb-4 mx-8"
                      onClick={() => changeShowMobileMenu()}
                    >
                      <span className="font-boldText text-lg text-[#333]">
                        {x.name}
                      </span>
                    </Link>
                  )}
                  <div
                    className={`overflow-hidden transition-all ${x.subExpansion ? 'max-h-[500px]' : 'max-h-0'
                      }`}
                  >
                    {x.routes?.map((y, j) => (
                      <Link
                        to={y.path}
                        key={j}
                        className="flex justify-between mx-12 mt-6 items-center border-b border-solid border-[#F2F2F2] pb-4"
                        onClick={() => changeShowMobileMenu()}
                      >
                        <span className="font-regularText text-lg text-[#333]">
                          {y.name}
                        </span>
                      </Link>
                    ))}
                  </div>
                </div>
              )
            })}
          </div>
          <div className="flex justify-center w-full mt-[43px] mb-[55px]">
            {!userInfo && (
              <Link
                to="/sign"
                className="w-[154px] mr-[35px] h-[52px] border border-solid border-[#828282] rounded-[60px] text-[#828282] text-center leading-[52px] bg-white"
                onClick={() => changeShowMobileMenu()}
              >
                Sign up
              </Link>
            )}
            <Link
              to="/createDao"
              className="w-[154px] h-[52px]  rounded-[60px] bg-[linear-gradient(270deg,#1E94FC_0%,#AED8FF_100%)] text-white text-center leading-[52px]"
              onClick={() => changeShowMobileMenu()}
            >
              Create DAO
            </Link>
          </div>
        </div>
      </div>
    </header>
  )
}
export default Header
